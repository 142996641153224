<template>
  <div class="settings">
    <!-- <div
      class="settings__card"
      @click="changeView('HomeMosaic')"
      v-if="showHomeMosaic && allowAccess(['glt admin'])"
    >
      <span>Home Mosaic</span>
      <p>Home mosaic setup</p>
    </div> -->
    <div
      class="settings__card"
      @click="changeView('CarouselMosaic')"
      v-if="showHomeMosaic && allowAccess(['glt admin'])"
    >
      <span>Carousel Mosaic</span>
      <p>Carousel mosaic setup</p>
    </div>
    <div
      class="settings__card"
      @click="apiKeysRoutingHandler()"
      v-if="allowAccess(['glt admin', 'account admin'])"
    >
      <span>API Keys</span>
      <p>List of API Keys to use in the Portal API.</p>
    </div>
    <div
      class="settings__card"
      @click="changeView('MyLocations')"
      v-if="allowAccess(['glt admin', 'account admin', 'customer agent'])"
    >
      <span>My Locations</span>
      <p>My Locations management</p>
    </div>
    <!-- Line Items -->
    <div
      class="settings__card"
      @click="changeView('MyLineItems')"
      v-if="allowAccess(['glt admin', 'account admin', 'customer agent'])"
    >
      <span>Commodities Catalogue</span>
      <p>Commodities Catalogue Management</p>
    </div>
    <div
      class="settings__card"
      @click="showMarkupsModal = true"
      v-if="allowAccess(['glt admin', 'account admin']) && isMagayaUser"
    >
      <span>Markups</span>
      <p>My default markups</p>
    </div>
    <div
      class="settings__card"
      @click="changeView('Themes')"
      v-if="tokenInfo.is_branded && !allowAccess(['customer agent'])"
    >
      <span>Branding Customization</span>
      <p>Portal themes and colors management</p>
    </div>
    <div class="settings__card" @click="changeView('Logos')" v-if="allowAccess(['account admin'])">
      <span>Logos Customization</span>
      <p>Customized application logo and documents logo attachments</p>
    </div>
    <div
      class="settings__card"
      @click="changeView('SettingsUserManagement', { accountId: tokenInfo.account_id })"
      v-if="allowAccess(['account admin']) && !isMagayaUser"
    >
      <span>User Management</span>
      <p>Account user administration.</p>
    </div>
    <div
      class="settings__card"
      @click="changeView('Statements')"
      v-if="!tokenInfo.is_branded && !isMagayaUser"
    >
      <span>Statements</span>
      <p>List of documents to account statements.</p>
    </div>
    <div
      class="settings__card"
      @click="changeView('FavoriteCarriers')"
      v-if="!isMagayaUser || allowAccess(['customer agent'])"
    >
      <span>My Favorite LTL Carriers</span>
      <p>List of account favorite LTL carriers.</p>
    </div>
    <div
      class="settings__card"
      @click="changeView('EmailNotifications')"
      v-if="(!tokenInfo.is_branded && !isMagayaUser) || allowAccess(['glt admin', 'account admin'])"
    >
      <span>Email Notifications</span>
      <p>Customize the notifications you would like to receive.</p>
    </div>
    <div
      class="settings__card"
      @click="showMagayaCredentialsModal = true"
      v-if="allowAccess(['glt admin', 'account admin']) && isMagayaUser"
    >
      <span>Magaya Credentials</span>
      <p>Change your username and password</p>
    </div>
    <CustomModal modalTitle="My Markups" v-model="showMarkupsModal" centered>
      <template #modal-content>
        <MarkupsModal />
      </template>
    </CustomModal>
    <CustomModal
      modalTitle="Magaya Credentials"
      v-model="showMagayaCredentialsModal"
      centered
      :showOverlay="loadingMagayaCredentials"
    >
      <template #modal-content>
        <MagayaCredentials
          @closeModal="showMagayaCredentialsModal = false"
          @loading="setLoadingMagayaCredentials"
        />
      </template>
    </CustomModal>
  </div>
</template>

<script>
import CustomModal from "@/components/CustomModal.vue";
import MarkupsModal from "./MyMarkups/components/MarkupsModal.vue";
import MagayaCredentials from "./MagayaCredentials/Index.vue";

export default {
  name: "Settings",
  components: {
    MarkupsModal,
    MagayaCredentials,
    CustomModal,
  },
  data() {
    return {
      role: null,
      showHomeMosaic: true,
      isMagayaUser: null,
      tokenInfo: null,
      showMarkupsModal: false,
      showMagayaCredentialsModal: false,
      loadingMagayaCredentials: false,
    };
  },
  created() {
    this.tokenInfo = this.$store.getters["login/getTokenInfo"];
    this.isMagayaUser = this.$store.getters["login/getTokenInfo"].magaya_guid;
    if (this.tokenInfo.magaya_guid) {
      this.showHomeMosaic = false;
    }
    this.role = this.tokenInfo.role.toLowerCase();
  },
  methods: {
    apiKeysRoutingHandler() {
      if (this.tokenInfo.role.toLowerCase() === "glt admin") {
        this.changeView("ApiKeys");
        return;
      }
      this.changeView("ClientApiKeys");
    },
    changeView(pathName, params = {}) {
      if (this.$route.name !== pathName) {
        this.$router.push({
          name: pathName,
          params,
        });
      }
    },
    allowAccess(roles) {
      return roles.includes(this.role);
    },
    closeModal(data) {
      this.showMarkupsModal = data;
    },
    setLoadingMagayaCredentials(data) {
      this.loadingMagayaCredentials = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-auto-rows: 120px;
  gap: 20px;
  padding: 4%;
  font-family: $font-family-portal;
  @media (max-width: 800px) {
    width: 97%;
    margin: auto;
    padding: 4% 0%;
  }
  &__card {
    width: auto;
    padding: 6%;
    border: 1px solid $color-border-container;
    border-radius: 10px;
    background-color: lighten($color-gray-light, 3%);
    color: $color-primary-company;
    -webkit-box-shadow: 2px 2px 5px 0px darken($color-gray-light, 20%);
    -moz-box-shadow: 2px 2px 5px 0px darken($color-gray-light, 20%);
    box-shadow: 2px 2px 5px 0px darken($color-gray-light, 20%);
    font-size: 14px;
    cursor: pointer;
    text-align: left;
    span {
      font-weight: bold;
    }
    p {
      font-weight: normal;
      margin-top: 10px;
    }
    &:hover {
      background-color: $color-gray-light;
    }
  }
}
::v-deep .modal-footer {
  display: none;
}
::v-deep .modal-header {
  display: none;
}
::v-deep .modal-content {
  width: 90%;
  margin: auto;
}
</style>
